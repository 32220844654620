<template>
<div   class="d-flex flex-column fill-height">
  <!-- Screen size -->
  <div
      v-if="!disableMobileView"
      class="d-flex justify-center  mb-4">
    <v-sheet
        :color="wsBACKGROUND"
        :style="`border: 2px solid ${wsBACKGROUND} !important`"
        class="wsRoundedLight d-flex"
    >
      <ws-tooltip :text="$t('DesktopView')">
        <v-icon
            v-ripple
            @click="toggleMobileView(false)"
            :style="`background: ${!EMAIL_MOBILE_VIEW ? wsATTENTION : wsBACKGROUND}`"
            style=" border-top-left-radius: 3px; border-bottom-left-radius: 3px"
            :color="!EMAIL_MOBILE_VIEW ? 'white' : wsACCENT"
            class="px-2 pointer">
          mdi-laptop
        </v-icon>
      </ws-tooltip>

      <v-divider vertical :style="`border-color : ${wsBACKGROUND}`"></v-divider>
      <ws-tooltip :text="$t('MobileView')">
        <v-sheet   v-ripple class="fill-height" :color="EMAIL_MOBILE_VIEW ? wsATTENTION : wsBACKGROUND">
          <v-icon
              @click="toggleMobileView(true)"

              style="border-top-right-radius: 3px; border-bottom-right-radius: 3px"
              size="18"
              :color="EMAIL_MOBILE_VIEW ? 'white' : wsACCENT"
              class="px-3 pointer">
            mdi-cellphone
          </v-icon>
        </v-sheet>
      </ws-tooltip>
    </v-sheet>
  </div>

  <v-sheet
      :width="!EMAIL_MOBILE_VIEW ? width : 320"
      style="border : 1px solid var(--wsBACKGROUND); height: 100%"
      class="mx-auto   overflow-hidden d-flex flex-column"
      :class="[{wsRoundedHalfTop : halfRounded},{ wsRoundedHalf:halfRounded }]"
  >
    <div class="d-flex pa-2">
      <v-icon v-for="i in 3" class="mr-1" :key="i" small :color="wsBACKGROUND">mdi-circle-outline</v-icon>
    </div>
    <v-divider style="border-color : var(--wsBACKGROUND)"></v-divider>

    <div class="overflow-y-auto flex-grow-1"
         style="position: relative;"
         :style="`background-color : ${bodyColor || wsLIGHTCARD}`"
    >
      <email-view
          :entity="entity"
          :uuid="uuid"
          :preview="preview"
          @body-color="bodyColor = $event"
          :scale="scale"
      />
    </div>


  </v-sheet>

</div>
</template>

<script>
import emailView from "@/modules/finemailer/components/emailConstructor/viewer/emailView.vue";
import finemailer from "@/modules/finemailer/mixins/finemailer";
export default {
  name: "EmailViewer",
  mixins : [finemailer],
  components : {
    emailView
  },
  props : {
    entity : {
      type : Object,
      default () { return {} }
    },
    width : {
      type : String,
      default : '800'
    },
    uuid : {
      type : String
    },
    preview : {
      type : Boolean,
      default : false
    },
    halfRounded : {
      type : Boolean,
      default : false
    },
    scale : {},
    disableMobileView : {
      type: Boolean,
      default : false
    }
  },
  data() {
    return {
      bodyColor : null
    }
  },
  methods : {
    toggleMobileView(value) {
      this.$store.state.finemailer.mobileView = value
    },
  }
}
</script>

<style scoped>

</style>